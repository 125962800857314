//LOGIN
export const POST_LOGIN = "/api/v1/access/login"
export const POST_PASSWORD_FORGET = "/api/v1/access/recovery"

//COUNTRY
export const GET_COUNTRIES_BY_ID = "/geo/countries"
export const GET_COUNTRIES_PAGINATE = "/geo/countries/paginate"
export const GET_COUNTRIES_PAGINATE_FILTERS = "/geo/countries/filters"
export const POST_CREATE_COUNTRY = "/geo/countries"
export const PUT_EDIT_COUNTRY = "/geo/countries"
export const DELETE_COUNTRY = "/geo/countries"

//STATE
export const GET_STATE_BY_ID = "/geo/states"
export const GET_STATE_PAGINATE = "/geo/states/paginate"
export const GET_STATE_PAGINATE_FILTERS = "/geo/states/filters"
export const POST_CREATE_STATE = "/geo/states"
export const PUT_EDIT_STATE = "/geo/states"
export const DELETE_STATE = "/geo/states"

//CITY
export const GET_CITY_BY_ID = "/geo/cities"
export const GET_CITY_PAGINATE = "/geo/cities/paginate"
export const GET_CITY_PAGINATE_FILTERS = "/geo/cities/filters"
export const POST_CREATE_CITY = "/geo/cities"
export const PUT_EDIT_CITY = "/geo/cities"
export const DELETE_CITY = "/geo/cities"

//COMPANIES
export const GET_COMPANIES_BY_ID = "/api/v1/companies"
export const GET_COMPANIES_ABOUT_US = "/api/v1/companies/aboutus"
export const GET_COMPANIES_PAGINATE = "/api/v1/companies/paginate"
export const GET_COMPANIES_PAGINATE_FILTERS = "/api/v1/companies/filters"
export const POST_CREATE_COMPANY = "/api/v1/companies"
export const POST_UPLOAD_COMPANY = "/api/v1/companies/upload"
export const PUT_EDIT_COMPANY = "/api/v1/companies"
export const DELETE_COMPANY = "/api/v1/companies"
export const GET_SEARCH_COMPANY = "/search/companies"
export const GET_COMPANIES_CRT = "/fiscal/nf/classicacao/empresas"
export const POST_CREATE_CRT_COMPANY = "/fiscal/nf/classicacao/empresas"
//MODELS SEGUIMENTS
export const GET_MODELS_SEGUIMENTS_BY_ID = "/api/v1/category/models"
export const GET_MODELS_SEGUIMENTS_PAGINATE = "/api/v1/category/models/paginate"
export const GET_MODELS_SEGUIMENTS_PAGINATE_FILTERS = "/api/v1/category/models/filters"
export const POST_CREATE_MODELS_SEGUIMENTS = "/api/v1/category/models"
export const POST_UPLOAD_MODELS_SEGUIMENTS = "/api/v1/category/models/upload"
export const PUT_EDIT_MODELS_SEGUIMENTS = "/api/v1/category/models"
export const DELETE_MODELS_SEGUIMENTS = "/api/v1/category/models"

//CATEGORIES
export const GET_CATEGORIES_BY_ID = "/api/v1/category"
export const GET_CATEGORIES_PAGINATE = "/api/v1/category/paginate"
export const GET_CATEGORIES_PAGINATE_FILTERS = "/api/v1/category/filters"
export const POST_CREATE_CATEGORIES = "/api/v1/category"
export const POST_UPLOAD_CATEGORIES = "/api/v1/category/upload"
export const PUT_EDIT_CATEGORIES = "/api/v1/category"
export const DELETE_CATEGORIES = "/api/v1/category"
export const GET_SEARCH_CATEGORY = "/search/categories"

//PAYMENT METHODS
export const GET_PAYMENT_METHOD_BY_ID = "/api/v1/payments/methods"
export const GET_PAYMENT_METHOD_PAGINATE = "/api/v1/payments/methods/paginate"
export const GET_PAYMENT_METHOD_PAGINATE_FILTERS = "/api/v1/payments/methods/filters"
export const POST_CREATE_PAYMENT_METHOD = "/api/v1/payments/methods"
export const POST_UPLOAD_PAYMENT_METHOD = "/api/v1/payments/methods/upload"
export const PUT_EDIT_PAYMENT_METHOD = "/api/v1/payments/methods"
export const DELETE_PAYMENT_METHOD = "/api/v1/payments/methods"
export const GET_SEARCH_PAYMENT_METHOD = "/search/payments/methods"

//PROMOTIONS
export const GET_PROMOTIONS_BY_ID = "/api/v1/promotions"
export const GET_PROMOTIONS_PAGINATE = "/api/v1/promotions/paginate"
export const GET_PROMOTIONS_PAGINATE_FILTERS = "/api/v1/promotions/filters"
export const POST_CREATE_PROMOTIONS = "/api/v1/promotions"
export const POST_UPLOAD_PROMOTIONS = "/api/v1/promotions/upload"
export const PUT_EDIT_PROMOTIONS = "/api/v1/promotions"
export const DELETE_PROMOTIONS = "/api/v1/promotions"
export const GET_SEARCH_PROMOTIONS = "/search/promotions"

//DISCOUNT COUPONS
export const GET_DISCOUNT_COUPOM_BY_ID = "/api/v1/discount-coupons"
export const GET_DISCOUNT_COUPOM_PAGINATE = "/api/v1/discount-coupons/paginate"
export const GET_DISCOUNT_COUPOM_PAGINATE_FILTERS = "/api/v1/discount-coupons/filters"
export const POST_CREATE_DISCOUNT_COUPOM = "/api/v1/discount-coupons"
export const POST_UPLOAD_DISCOUNT_COUPOM = "/api/v1/discount-coupons/upload"
export const PUT_EDIT_DISCOUNT_COUPOM = "/api/v1/discount-coupons"
export const DELETE_DISCOUNT_COUPOM = "/api/v1/discount-coupons"
export const GET_SEARCH_DISCOUNT_COUPOM = "/search/discount-coupons"


//DISCOUNT COUPONS company
export const GET_DISCOUNT_COUPOM_COMPANY_BY_ID = "/api/v1/companies/discount-coupons"
export const GET_DISCOUNT_COUPOM_COMPANY_PAGINATE = "/api/v1/companies/discount-coupons/paginate"
export const GET_DISCOUNT_COUPOM_COMPANY_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_DISCOUNT_COUPOM_COMPANY = "/api/v1/companies/discount-coupons"
export const PUT_EDIT_DISCOUNT_COUPOM_COMPANY = "/api/v1/companies/discount-coupons"
export const DELETE_DISCOUNT_COUPOM_COMPANY = "/api/v1/companies/discount-coupons"

//Payment Methods company
export const GET_PAYMENT_METHODS_COMPANY_BY_ID = "/api/v1/companies/payment-methods"
export const GET_PAYMENT_METHODS_COMPANY_PAGINATE = "/api/v1/companies/payment-methods/paginate"
export const GET_PAYMENT_METHODS_COMPANY_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_PAYMENT_METHODS_COMPANY = "/api/v1/companies/payment-methods"
export const PUT_EDIT_PAYMENT_METHODS_COMPANY = "/api/v1/companies/payment-methods"
export const DELETE_PAYMENT_METHODS_COMPANY = "/api/v1/companies/payment-methods"

//Schedulers company
export const GET_SCHEDULER_COMPANY_BY_ID = "/api/v1/companies/schedules"
export const GET_SCHEDULER_COMPANY_PAGINATE = "/api/v1/companies/schedules/paginate"
export const GET_SCHEDULER_COMPANY_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_SCHEDULER_COMPANY = "/api/v1/companies/schedules"
export const PUT_EDIT_SCHEDULER_COMPANY = "/api/v1/companies/schedules"
export const DELETE_SCHEDULER_COMPANY = "/api/v1/companies/schedules"

//Service Radius company
export const GET_SERVICE_RADIUS_COMPANY_BY_ID = "/api/v1/companies/service-radius"
export const GET_SERVICE_RADIUS_COMPANY_PAGINATE = "/api/v1/companies/service-radius/paginate"
export const GET_SERVICE_RADIUS_COMPANY_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_SERVICE_RADIUS_COMPANY = "/api/v1/companies/service-radius"
export const PUT_EDIT_SERVICE_RADIUS_COMPANY = "/api/v1/companies/service-radius"
export const DELETE_SERVICE_RADIUS_COMPANY = "/api/v1/companies/service-radius"

//Drivers
export const GET_DRIVERS_COMPANY_BY_ID = "/drivers"
export const GET_DRIVERS_COMPANY_PAGINATE = "/drivers/paginate"
export const GET_DRIVERS_COMPANY_PAGINATE_FILTERS = "/drivers/filters"
export const POST_CREATE_DRIVERS_COMPANY = "/drivers"
export const PUT_EDIT_DRIVERS_COMPANY = "/drivers"
export const DELETE_DRIVERS_COMPANY = "/drivers"

//EDGES
export const GET_EDGES_BY_ID = "/api/v1/category/edges"
export const GET_EDGES_PAGINATE = "/api/v1/category/edges/paginate"
export const GET_EDGES_PAGINATE_FILTERS = "/api/v1/category/edges/filters"
export const POST_CREATE_EDGES = "/api/v1/category/edges"
export const PUT_EDIT_EDGES = "/api/v1/category/edges"
export const DELETE_EDGES = "/api/v1/category/edges"

//PASTAS
export const GET_PASTAS_BY_ID = "/api/v1/category/pastas"
export const GET_PASTAS_PAGINATE = "/api/v1/category/pastas/paginate"
export const GET_PASTAS_PAGINATE_FILTERS = "/api/v1/category/pastas/filters"
export const POST_CREATE_PASTAS = "/api/v1/category/pastas"
export const PUT_EDIT_PASTAS = "/api/v1/category/pastas"
export const DELETE_PASTAS = "/api/v1/category/pastas"

//SIZES
export const GET_SIZES_BY_CATEGORY_ID = "/api/v1/category/size"
export const GET_SIZES_BY_ID = "/api/v1/category/sizes"
export const GET_SIZES_PAGINATE = "/api/v1/category/sizes/paginate"
export const GET_SIZES_PAGINATE_FILTERS = "/api/v1/category/sizes/filters"
export const POST_CREATE_SIZES = "/api/v1/category/sizes"
export const PUT_EDIT_SIZES = "/api/v1/category/sizes"
export const DELETE_SIZES = "/api/v1/category/sizes"

//PRODUCTS
export const GET_PRODUCTS_GENERIC_BY_ID = "/api/v1/products/generic"
export const GET_PRODUCTS_PIZZA_BY_ID = "/api/v1/products/pizza"
export const GET_PRODUCTS_BY_ID = "/api/v1/products"
export const GET_PRODUCTS_PAGINATE = "/api/v1/products/paginate"
export const GET_PRODUCTS_PAGINATE_FILTERS = "/api/v1/products/filters"
export const POST_CREATE_PRODUCT = "/api/v1/products"
export const POST_UPLOAD_PRODUCT = "/upload/products"
export const PUT_EDIT_PRODUCT = "/api/v1/products/update"
export const DELETE_PRODUCT = "/api/v1/products"
export const GET_SEARCH_PRODUCT = "/search/products"

//PRODUCTS SIZE
export const GET_PRODUCTS_SIZE_BY_ID = "/api/v1/products/sizes"
export const GET_PRODUCTS_SIZE_PAGINATE = "/api/v1/products/sizes/paginate"
export const POST_CREATE_PRODUCTS_SIZE = "/api/v1/products/sizes"
export const PUT_EDIT_PRODUCTS_SIZE = "/api/v1/products/sizes"
export const DELETE_PRODUCTS_SIZE = "/api/v1/products/sizes"

//PRODUCTS COMPLEMENT GROUP
export const GET_PRODUCTS_COMPLEMENT_BY_ID = "/api/v1/complements"
export const GET_PRODUCTS_COMPLEMENT_PAGINATE = "/api/v1/complements/paginate"
export const POST_CREATE_PRODUCTS_COMPLEMENT = "/api/v1/complements"
export const PUT_EDIT_PRODUCTS_COMPLEMENT = "/api/v1/complements"
export const DELETE_PRODUCTS_COMPLEMENT = "/api/v1/complements"
export const GET_PRODUCTS_COMPLEMENT_BY_PRODUCT_ID = "/api/v1/complements/product"

//GEO
export const GET_GEO_SEARCH_ADDRESS = "/geo/search/map/address"
export const GET_GEO_REVERSE = "/geo/search/map/reverse"

//VIACEP
export const GET_VIA_CEP = "/geo/search/map/reverse"


//NF COFINS
export const GET_NF_TRIB_COFINS_BY_ID = "/fiscal/nf/classicacao/tributacao/cofins"
export const GET_NF_TRIB_COFINS_PAGINATE = "/fiscal/nf/classicacao/tributacao/cofins"
export const GET_NF_TRIB_COFINS_PAGINATE_FILTERS = "/nf/v1/tributacao/cofins/filters"
export const POST_CREATE_NF_TRIB_COFINS = "/nf/v1/tributacao/cofins"
export const PUT_EDIT_NF_TRIB_COFINS = "/fiscal/nf/classicacao/tributacao/cofins"
export const DELETE_NF_TRIB_COFINS = "/fiscal/nf/classicacao/tributacao/cofins"
export const GET_SEARCH_NF_TRIB_COFINS = "/search/tributacao/cofins"

//NF ORIGEM
export const GET_NF_ORIGEM_BY_ID = "/fiscal/nf/classicacao/tributacao/origem"
export const GET_NF_ORIGEM_PAGINATE = "/fiscal/nf/classicacao/tributacao/origem"
export const GET_NF_ORIGEM_PAGINATE_FILTERS = "/nf/v1/tributacao/origem/filters"
export const POST_CREATE_NF_ORIGEM = "/nf/v1/tributacao/origem"
export const PUT_EDIT_NF_ORIGEM = "/fiscal/nf/classicacao/tributacao/origem"
export const DELETE_NF_ORIGEM = "/fiscal/nf/classicacao/tributacao/origem"
export const GET_SEARCH_NF_ORIGEM = "/search/tributacao/origem"

//NF CSOSN
export const GET_NF_TRIB_CSOSN_BY_ID = "/fiscal/nf/classicacao/tributacao/csosn"
export const GET_NF_TRIB_CSOSN_PAGINATE = "/fiscal/nf/classicacao/tributacao/csosn"
export const GET_NF_TRIB_CSOSN_PAGINATE_FILTERS = "/nf/v1/tributacao/csosn/filters"
export const POST_CREATE_NF_TRIB_CSOSN = "/nf/v1/tributacao/csosn"
export const PUT_EDIT_NF_TRIB_CSOSN = "/fiscal/nf/classicacao/tributacao/csosn"
export const DELETE_NF_TRIB_CSOSN = "/fiscal/nf/classicacao/tributacao/csosn"
export const GET_SEARCH_NF_TRIB_CSOSN = "/search/tributacao/csosn"

//NF ICMSCST
export const GET_NF_TRIB_ICMSCST_BY_ID = "/fiscal/nf/classicacao/tributacao/icmscst"
export const GET_NF_TRIB_ICMSCST_PAGINATE = "/fiscal/nf/classicacao/tributacao/icmscst"
export const GET_NF_TRIB_ICMSCST_PAGINATE_FILTERS = "/nf/v1/tributacao/icmscst/filters"
export const POST_CREATE_NF_TRIB_ICMSCST = "/nf/v1/tributacao/icmscst"
export const PUT_EDIT_NF_TRIB_ICMSCST = "/fiscal/nf/classicacao/tributacao/icmscst"
export const DELETE_NF_TRIB_ICMSCST = "/fiscal/nf/classicacao/tributacao/icmscst"
export const GET_SEARCH_NF_TRIB_ICMSCST = "/search/tributacao/icmscst"

//NF IPI
export const GET_NF_TRIB_IPI_BY_ID = "/fiscal/nf/classicacao/tributacao/ipi"
export const GET_NF_TRIB_IPI_PAGINATE = "/fiscal/nf/classicacao/tributacao/ipi"
export const GET_NF_TRIB_IPI_PAGINATE_FILTERS = "/nf/v1/tributacao/ipi/filters"
export const POST_CREATE_NF_TRIB_IPI = "/nf/v1/tributacao/ipi"
export const PUT_EDIT_NF_TRIB_IPI = "/fiscal/nf/classicacao/tributacao/ipi"
export const DELETE_NF_TRIB_IPI = "/fiscal/nf/classicacao/tributacao/ipi"
export const GET_SEARCH_NF_TRIB_IPI = "/search/tributacao/ipi"

//NF MOD BC ICMS
export const GET_NF_TRIB_MOD_BC_ICMS_BY_ID = "/fiscal/nf/classicacao/tributacao/modbcicms"
export const GET_NF_TRIB_MOD_BC_ICMS_PAGINATE = "/fiscal/nf/classicacao/tributacao/modbcicms"
export const GET_NF_TRIB_MOD_BC_ICMS_PAGINATE_FILTERS = "/nf/v1/tributacao/modbcicms/filters"
export const POST_CREATE_NF_TRIB_MOD_BC_ICMS = "/nf/v1/tributacao/modbcicms"
export const PUT_EDIT_NF_TRIB_MOD_BC_ICMS = "/fiscal/nf/classicacao/tributacao/modbcicms"
export const DELETE_NF_TRIB_MOD_BC_ICMS = "/fiscal/nf/classicacao/tributacao/modbcicms"
export const GET_SEARCH_NF_TRIB_MOD_BC_ICMS = "/search/tributacao/modbcicms"

//NF MOD BC ICMS ST
export const GET_NF_TRIB_MOD_BC_ICMS_ST_BY_ID = "/fiscal/nf/classicacao/tributacao/modbcicmsst"
export const GET_NF_TRIB_MOD_BC_ICMS_ST_PAGINATE = "/fiscal/nf/classicacao/tributacao/modbcicmsst"
export const GET_NF_TRIB_MOD_BC_ICMS_ST_PAGINATE_FILTERS = "/nf/v1/tributacao/modbcicmsst/filters"
export const POST_CREATE_NF_TRIB_MOD_BC_ICMS_ST = "/nf/v1/tributacao/modbcicmsst"
export const PUT_EDIT_NF_TRIB_MOD_BC_ICMS_ST = "/fiscal/nf/classicacao/tributacao/modbcicmsst"
export const DELETE_NF_TRIB_MOD_BC_ICMS_ST = "/fiscal/nf/classicacao/tributacao/modbcicmsst"
export const GET_SEARCH_NF_TRIB_MOD_BC_ICMS_ST = "/search/tributacao/modbcicmsst"

//NF MODELO
export const GET_NF_MODELO_BY_ID = "/fiscal/nf/classicacao/tributacao/modelo"
export const GET_NF_MODELO_PAGINATE = "/fiscal/nf/classicacao/tributacao/modelo"
export const GET_NF_MODELO_PAGINATE_FILTERS = "/nf/v1/tributacao/modelo/filters"
export const POST_CREATE_NF_MODELO = "/nf/v1/tributacao/modelo"
export const PUT_EDIT_NF_MODELO = "/fiscal/nf/tributacao/modelo"
export const DELETE_NF_MODELO = "/fiscal/nf/tributacao/modelo"
export const GET_SEARCH_NF_MODELO = "/search/tributacao/modelo"

//NF PIS
export const GET_NF_PIS_BY_ID = "/fiscal/nf/classicacao/tributacao/pis"
export const GET_NF_PIS_PAGINATE = "/fiscal/nf/classicacao/tributacao/pis"
export const GET_NF_PIS_PAGINATE_FILTERS = "/nf/v1/tributacao/pis/filters"
export const POST_CREATE_NF_PIS = "/nf/v1/tributacao/pis"
export const PUT_EDIT_NF_PIS = "/fiscal/nf/classicacao/tributacao/pis"
export const DELETE_NF_PIS = "/fiscal/nf/classicacao/tributacao/pis"
export const GET_SEARCH_NF_PIS = "/search/tributacao/pis"

//NF CNAE
export const GET_NF_CNAE_BY_ID = "/fiscal/nf/classicacao/cnae"
export const GET_NF_CNAE_PAGINATE = "/fiscal/nf/classicacao/cnae"
export const GET_NF_CNAE_PAGINATE_FILTERS = "/nf/v1/tributacao/cnae/filters"
export const POST_CREATE_NF_CNAE = "/nf/v1/tributacao/cnae"
export const PUT_EDIT_NF_CNAE = "/fiscal/nf/classicacao/cnae"
export const DELETE_NF_CNAE = "/fiscal/nf/classicacao/cnae"
export const GET_SEARCH_NF_CNAE = "/search/cnae"


//NF CFOP
export const GET_NF_TRIB_CFOP_BY_ID = "/fiscal/nf/classicacao/tributacao/cfop"
export const GET_NF_TRIB_CFOP_PAGINATE = "/fiscal/nf/classicacao/tributacao/cfop"
export const GET_NF_TRIB_CFOP_PAGINATE_FILTERS = "/nf/v1/tributacao/cfop/filters"
export const POST_CREATE_NF_TRIB_CFOP = "/nf/v1/tributacao/cfop"
export const PUT_EDIT_NF_TRIB_CFOP = "/fiscal/nf/classicacao/tributacao/cfop"
export const DELETE_NF_TRIB_CFOP = "/fiscal/nf/classicacao/tributacao/cfop"
export const GET_SEARCH_NF_TRIB_CFOP = "/search/tributacao/cfop"

//NF CRT
export const GET_NF_CRT_BY_ID = "/fiscal/nf/classicacao/tributacao/crt"
export const GET_NF_CRT_PAGINATE = "/fiscal/nf/classicacao/tributacao/crt"
export const GET_NF_CRT_PAGINATE_FILTERS = "/nf/v1/tributacao/crt/filters"
export const POST_CREATE_NF_CRT = "/nf/v1/tributacao/crt"
export const PUT_EDIT_NF_CRT = "/fiscal/nf/classicacao/tributacao/crt"
export const DELETE_NF_CRT = "/fiscal/nf/classicacao/tributacao/crt"
export const GET_SEARCH_NF_CRT = "/search/tributacao/crt"

//NF TIPO OPERAÇÃO
export const GET_NF_TIPO_OPERACAO_BY_ID = "/fiscal/nf/classicacao/tributacao/operacao"
export const GET_NF_TIPO_OPERACAO_PAGINATE = "/fiscal/nf/classicacao/tributacao/operacao"
export const GET_NF_TIPO_OPERACAO_PAGINATE_FILTERS = "/nf/v1/tributacao/operacao/filters"
export const POST_CREATE_NF_TIPO_OPERACAO = "/nf/v1/tributacao/operacao"
export const PUT_EDIT_NF_TIPO_OPERACAO = "/fiscal/nf/classicacao/tributacao/operacao"
export const DELETE_NF_TIPO_OPERACAO = "/fiscal/nf/classicacao/tributacao/operacao"
export const GET_SEARCH_NF_TIPO_OPERACAO = "/search/tributacao/operacao"

//TABELA IBPT
export const GET_TAB_IBPT_BY_ID = "/fiscal/nf/classicacao/tributacao/ibpt"
export const GET_TAB_IBPT_PAGINATE = "/fiscal/nf/classicacao/tributacao/ibpt"
export const GET_TAB_IBPT_PAGINATE_FILTERS = "/nf/v1/tributacao/ibpt/filters"
export const POST_CREATE_TAB_IBPT = "/nf/v1/tributacao/ibpt"
export const PUT_EDIT_TAB_IBPT = "/fiscal/nf/classicacao/tributacao/ibpt"
export const DELETE_TAB_IBPT = "/fiscal/nf/classicacao/tributacao/ibpt"
export const GET_SEARCH_TAB_IBPT = "/search/tributacao/ibpt"
export const IMPORT_TAB_IBPT = "/imports/ibpt"

//TABELA CEST
export const GET_TAB_CEST_BY_ID = "/fiscal/nf/classicacao/tributacao/cest"
export const GET_TAB_CEST_PAGINATE = "/fiscal/nf/cest"
export const GET_TAB_CEST_PAGINATE_FILTERS = "/nf/v1/tributacao/cest/filters"
export const POST_CREATE_TAB_CEST = "/nf/v1/tributacao/cest"
export const PUT_EDIT_TAB_CEST = "/fiscal/nf/classicacao/tributacao/cest"
export const DELETE_TAB_CEST = "/fiscal/nf/classicacao/tributacao/cest"
export const GET_SEARCH_TAB_CEST = "/search/cest"
export const IMPORT_TAB_CEST = "/imports/cest"

//SPED ITEM
export const GET_SPED_ITEM_BY_ID = "/fiscal/nf/classicacao/tributacao/sped-item"
export const GET_SPED_ITEM_PAGINATE = "/fiscal/nf/sped-item"
export const GET_SPED_ITEM_PAGINATE_FILTERS = "/nf/v1/tributacao/sped-item/filters"
export const POST_CREATE_SPED_ITEM = "/nf/v1/tributacao/sped-item"
export const PUT_EDIT_SPED_ITEM = "/fiscal/nf/classicacao/tributacao/sped-item"
export const DELETE_SPED_ITEM = "/fiscal/nf/classicacao/tributacao/sped-item"
export const GET_SEARCH_SPED_ITEM = "/search/sped-item"
export const IMPORT_SPED_ITEM = "/imports/sped-item"

//CLASSIFICACAO PRODUTO
export const GET_CLASSIFICACAO_PRODUTO_BY_ID = "/fiscal/nf/classicacao/produtos/id"
export const GET_CLASSIFICACAO_PRODUTO_PAGINATE = "/fiscal/nf/classicacao/produtos/paginate"
export const GET_CLASSIFICACAO_PRODUTO_PAGINATE_FILTERS = "/fiscal/nf/classicacao/produtos/filters"
export const POST_CREATE_CLASSIFICACAO_PRODUTO = "/fiscal/nf/classicacao/produtos"
export const PUT_EDIT_CLASSIFICACAO_PRODUTO = "/fiscal/nf/classicacao/produtos"
export const DELETE_CLASSIFICACAO_PRODUTO = "/fiscal/nf/classicacao/produtos"
export const GET_SEARCH_CLASSIFICACAO_PRODUTO = "/search/classicacao/produtos"

//SETTINGS
export const GET_SETTINGS_BY_ID = "/settings"
export const GET_SETTINGS_BY_KEY = "/settings/key"
export const GET_SETTINGS_PAGINATE = "/settings/paginate"
export const GET_SETTINGS_PAGINATE_FILTERS = "/settings/filters"
export const POST_CREATE_SETTINGS = "/settings"
export const POST_CREATE_OR_EDIT_SETTINGS = "/settings/create-or-edit"
export const PUT_EDIT_SETTINGS = "/settings"
export const DELETE_SETTINGS = "/settings"

//TIPO NF
export const GET_TIPO_NF_BY_ID = "/fiscal/nf/tipo"
export const GET_TIPO_NF_PAGINATE = "/fiscal/nf/tipo/paginate"
export const GET_TIPO_NF_PAGINATE_FILTERS = "/fiscal/nf/tipo/filters"
export const POST_CREATE_TIPO_NF = "/fiscal/nf/tipo"
export const PUT_EDIT_TIPO_NF = "/fiscal/nf/tipo"
export const DELETE_TIPO_NF = "/fiscal/nf/tipo"
export const GET_SEARCH_TIPO_NF = "/search/tipo"


//TABELA CATEGORIA GOOGLE
export const GET_GOOGLE_MERCHANT_BY_ID = "/api/v1/social-midia/categories/gmerchant"
export const GET_GOOGLE_MERCHANT_PAGINATE = "/api/v1/social-midia/categories/gmerchant/paginate"
export const GET_GOOGLE_MERCHANT_PAGINATE_FILTERS = "/api/v1/social-midia/categories/gmerchant/filters"
export const POST_CREATE_GOOGLE_MERCHANT = "/api/v1/social-midia/categories/gmerchant"
export const PUT_EDIT_GOOGLE_MERCHANT = "/api/v1/social-midia/categories/gmerchant"
export const DELETE_GOOGLE_MERCHANT = "/api/v1/social-midia/categories/gmerchant"
export const GET_SEARCH_GOOGLE_MERCHANT = "/search/categories/gmerchant"
export const IMPORT_GOOGLE_MERCHANT = "/imports/gmerchant"

//TABELA CATEGORIA META FACEBOOK
export const GET_META_FACEBOOK_BY_ID = "/api/v1/social-midia/categories/meta-facebook"
export const GET_META_FACEBOOK_PAGINATE = "/api/v1/social-midia/categories/meta-facebook/paginate"
export const GET_META_FACEBOOK_PAGINATE_FILTERS = "/api/v1/social-midia/categories/meta-facebook/filters"
export const POST_CREATE_META_FACEBOOK = "/api/v1/social-midia/categories/meta-facebook"
export const PUT_EDIT_META_FACEBOOK = "/api/v1/social-midia/categories/meta-facebook"
export const DELETE_META_FACEBOOK = "/api/v1/social-midia/categories/meta-facebook"
export const GET_SEARCH_META_FACEBOOK = "/search/categories/mfacebook"
export const IMPORT_META_FACEBOOK = "/imports/meta"

//MACHINE LEARNING INTENT
export const GET_EXPRESSION_ML_PAGINATE = "/ml/intents/docs/paginate";
export const GET_INTENT_ML_PAGINATE = "/ml/intents/paginate";
export const GET_INTENT_ML_BY_ID = "/ml/intents";
export const GET_EXPRESSION_ML_BY_ID = "/ml/intents/docs";
export const POST_CREATE_INTENT_ML = "/ml/intents";
export const PUT_EDIT_INTENT_ML = "/ml/intents";
export const DELETE_INTENT_ML = "/ml/intents";
export const POST_ADD_QUESTION_PHRASES_INTENT = "/ml/intents/docs";
export const DELETE_QUESTION_PHRASES_INTENT = "/ml/intents/docs";
export const POST_ADD_ANSWERS_PHRASES_INTENT = "/ml/intents/answers";
export const DELETE_ANSWERS_PHRASES_INTENT = "/ml/intents/answers";
