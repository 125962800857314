import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ButtonCustom from "../../../../../components/ButtonCustom";
import { connect } from "react-redux";
import { createProduct } from "../../../../../store/categories/options/products/actions"
import { createProductSize } from "../../../../../store/categories/options/products/sizes/actions"
import { findSizesByIdCategories } from "../../../../../store/actions"
import { findProductComplements } from "../../../../../store/categories/options/products/complements/actions"

import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Form, Stack } from "react-bootstrap";
import Autocomplete from "../../../../../components/Autocomplete";

import Dropzone from "react-dropzone";
import InputMask from 'react-input-mask';


class FormProductComplement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mandatory: 0,
            product_id: 0,
            min_select: 0,
            max_select: 1,
            activeTab: "1",
            field_search_product: 0,
            isEnableButtonNew: false,
            complements: [
                {
                    id: 0,
                    sku: '',
                    name: '',
                    isCreated: false,
                    isEdited: false,
                    description: '',
                    image: null,
                    status: 1,
                    price: null,
                    file: null,
                    existsFile: false,

                },

            ]


        }

        this.handleFields = this.handleFields.bind(this);
        this.handleFieldsComplementsList = this.handleFieldsComplementsList.bind(this);
        this.handleRadioFields = this.handleRadioFields.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleAddNewComplement = this.handleAddNewComplement.bind(this);
        this.handleSaveNewComplement = this.handleSaveNewComplement.bind(this);
        this.handleRemoveComplement = this.handleRemoveComplement.bind(this);
        this.handleUpdateComplement = this.handleUpdateComplement.bind(this);
        this.handleEditComplement = this.handleEditComplement.bind(this);
        this.handleSub = this.handleSub.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleAutocomplete = this.handleAutocomplete.bind(this);

    }
    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState[name_entity] = code

            this.setState(newState)
        }
    }
    mountDataForm() {
        const { name, mandatory, product_id, min_select, max_select, activeTab, isEnableButtonNew, complements } = this.state
        return [{ name, mandatory, product_id, min_select, max_select, activeTab, isEnableButtonNew, complements }];

    }

    handleAdd(e, type) {

        if (type === 1) {
            this.setState({ min_select: (this.state.min_select + 1) })
        }
        if (type === 2) {
            this.setState({ max_select: (this.state.max_select + 1) })
        }

    }

    handleDrop(e, index) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            const { complements } = this.state;
            complements[index]['file'] = file;
            complements[index]['existsFile'] = true;
            complements[index]['image'] = reader.result;

            this.setState({ complements });
        }

        reader.readAsDataURL(file)

    }

    handleSub(e, type) {

        if (type === 1) {
            if (this.state.min_select > 0) {
                this.setState({ min_select: (this.state.min_select - 1) })
            } else {
                this.setState({ min_select: 0 })
            }

        }
        if (type === 2) {
            if (this.state.max_select > 0) {
                this.setState({ max_select: (this.state.max_select - 1) })
            } else {
                this.setState({ max_select: 0 })
            }

        }
    }

    handleUpdateComplement(event, index) {
        const { complements } = this.state;
        complements[index]['isCreated'] = true;
        complements[index]['isEdited'] = true;
    }

    handleSaveNewComplement(event, index) {
        const { complements } = this.state;
        complements[index]['isCreated'] = true;
        this.setState({ complements, isEnableButtonNew: true })
    }

    handleAddNewComplement(event) {
        const { complements } = this.state;
        complements.push({
            id: 0,
            sku: '',
            name: '',
            isCreated: false,
            isEdited: false,
            description: '',
            image: null,
            status: 1,
            price: 0,

        });

        this.setState({ complements, isEnableButtonNew: false })
    }

    handleRemoveComplement(event, index) {
        const { complements } = this.state;
        if (complements.length > 1) {
            complements.splice(index, 1);
        } else {
            complements[index]['isCreated'] = false;
        }
        if (complements.length === 1) {
            complements[index]['isCreated'] = false;
        }

        this.setState({ complements })
    }

    handleEditComplement(event, index) {
        const { complements } = this.state;
        complements[index]['isEdited'] = true;
        complements[index]['isCreated'] = false;

        this.setState({ complements })
    }

    handleRadioFields(event) {
        const { name, value } = event.target
        if (parseInt(value) === 1) {
            this.setState({ min_select: 1 })
        }
        if (parseInt(value) === 0) {
            this.setState({ min_select: 0 })
        }
        this.setState({ mandatory: parseInt(value) })

    }

    handleFieldsComplementsList(index, e) {
        const { name, value } = e.target
        let complements = [...this.state.complements];
        let item = { ...complements[index] };
        item[name] = value;
        complements[index] = item;
        this.setState({ complements });
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    render() {
        const { complements } = this.state;

        return (
            <Card>
                <CardBody>
                    <Row className="align-items-md-center  mb-3">
                        <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                                <h4 className="card-title mb-4">{this.state.name ? this.state.name : 'Novo grupo de complementos'}</h4>
                            </div>
                            <div className="text-md-right ms-auto p-2">
                                <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                            </div>
                        </Col>
                    </Row>

                    <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                            >
                                Detalhe
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "2",
                                })}
                            >
                                Complementos
                            </NavLink>
                        </NavItem>

                    </ul>
                    <TabContent
                        activeTab={this.state.activeTab}
                        className="py-4"
                    >
                        <TabPane tabId="1">
                            <PerfectScrollbar style={{ height: "410px" }}>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-group mt-2">
                                            <label htmlFor=""
                                                className="control-label"> Nome do grupo de complementos <small
                                                    className="text-muted">Obrigatório</small></label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                placeholder="Informe o nome"
                                                name="name"
                                                onChange={this.handleFields}
                                                value={this.state.name} />

                                        </div>
                                    </Col>
                                    <Col sm={12} className="mt-2">
                                        <div className="card-header rounded">
                                            <div className="d-inline">
                                                <span className="h3 card-title">Obrigatoriedade</span>
                                                <p className="card-title-desc mt-2">Indique se essa categoria é
                                                    necessária para pedir o prato</p>
                                            </div>

                                            <div className="form-group mt-2">
                                                <Form.Check
                                                    type="radio"
                                                    checked={(this.state.mandatory === 0 ? true : false)}
                                                    label="Opcional"
                                                    name="mandatory"
                                                    className="card-title-desc"
                                                    onChange={this.handleRadioFields}
                                                    value={0} />
                                            </div>
                                            <div className="form-group">
                                                <Form.Check
                                                    type="radio"
                                                    checked={(this.state.mandatory === 1 ? true : false)}
                                                    label="Obrigatório"
                                                    className="card-title-desc"
                                                    name="mandatory"
                                                    onChange={this.handleRadioFields}
                                                    value={1} />

                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm={12}>
                                        <div className="card-header rounded mt-1">
                                            <div className="d-inline">
                                                <span className="h3 card-title">Quantidade</span>
                                                <p className="card-title-desc mt-2">Indique quantos itens podem ser
                                                    selecionados</p>
                                            </div>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col>
                                                            <div className="form-group">
                                                                <label
                                                                    className="control-label">Minimo</label>
                                                                <div disabled={(this.state.mandatory === 1)}
                                                                    className={(this.state.mandatory === 1) ? "rounded border border-dark text-center pt-2" : "rounded border-1 text-muted text-center  pt-2"}>
                                                                    <a disabled={(this.state.mandatory === 1)}
                                                                        onClick={(this.state.mandatory === 1) && ((e) => this.handleSub(e, 1))}
                                                                        className="p-2"><i className="bx bx-plus"></i></a>
                                                                    <label disabled={(this.state.mandatory === 1)}
                                                                        className="p-1">{this.state.min_select}</label>
                                                                    <a disabled={(this.state.mandatory === 1)}
                                                                        onClick={(this.state.mandatory === 1) && ((e) => this.handleAdd(e, 1))}
                                                                        className="p-2"><i className="bx bx-plus"></i></a>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form-group ">
                                                                <label
                                                                    className="control-label">Máximo</label>
                                                                <div
                                                                    className="rounded border border-dark text-center pt-2">
                                                                    <a onClick={(e) => this.handleSub(e, 2)}
                                                                        className="p-2"><i className="bx bx-plus"></i></a>
                                                                    <label
                                                                        className="p-1">{this.state.max_select}</label>
                                                                    <a onClick={(e) => this.handleAdd(e, 2)}
                                                                        className="p-2"><i className="bx bx-plus"></i></a>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>
                            </PerfectScrollbar>
                        </TabPane>
                        <TabPane tabId="2">
                            <PerfectScrollbar style={{ height: "410px" }}>
                                <div>

                                    {
                                        complements.map((item, index) =>
                                        (<Row key={index} className="border rounded p-1 m-2">
                                            <Col sm={12}>
                                                {this.state.complements.length > 1 &&
                                                    (
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="text-left ms-auto p-2">
                                                                    <i onClick={(e) => this.handleRemoveComplement(e, index)}
                                                                        className="bx bx-x"></i>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <div className="text-right ms-auto p-2">
                                                                    <i onClick={(e) => { this.handleEditComplement(e, index) }}
                                                                        className="bx bx-pencil"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )

                                                }
                                            </Col>
                                            <Col sm={4}>

                                                <Col sm={12} className="mt-2"
                                                    disabled={!this.state.complements[index]['isCreated']}>
                                                    <Dropzone disabled={this.state.complements[index]['isCreated']}
                                                        onDrop={(event) => this.handleDrop(event, index)}>

                                                        {({ getRootProps, getInputProps }) => (

                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {this.state.complements[index]['image'] !== '' && this.state.complements[index]['image'] !== null
                                                                    ?
                                                                    <img style={{ width: '40px', height: '100px' }}
                                                                        src={this.state.complements[index]['image']} />
                                                                    : <p style={{ marginTop: '40%' }}
                                                                        className="text-center">Arraste e
                                                                        solte ou
                                                                        clique
                                                                        para adicionar a imagem</p>
                                                                }

                                                            </div>

                                                        )}

                                                    </Dropzone>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">{this.props.t("SKU")}</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="123"
                                                            name="sku"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(e) => { this.handleFieldsComplementsList(index, e) }}
                                                            value={this.state.complements[index]['sku']} />

                                                    </div>
                                                </Col>

                                            </Col>
                                            <Col sm={8}>
                                                <Col sm={12}>

                                                    <div className="form-group mt-2">
                                                        <Stack direction="horizontal" gap={2} >
                                                            <div className='w-75'>
                                                                <label htmlFor=""
                                                                    className="control-label">{this.state.field_search_product === 0 ? 'Sabor ou nome' : 'Pesquisar Sabor ou nome'}</label>
                                                            </div>
                                                            <div className='w-25'>
                                                                <a onClick={() => {
                                                                    this.setState({ field_search_product: this.state.field_search_product === 1 ? 0 : 1 })
                                                                }}>
                                                                    {
                                                                        this.state.field_search_product === 1
                                                                            ?
                                                                            <i className="bx bx-form"> + Cadastrar</i>
                                                                            :
                                                                            <i className="bx bx-search"> Pesquisar</i>
                                                                    }

                                                                </a>
                                                            </div>

                                                        </Stack>
                                                        {
                                                            this.state.field_search_product === 0
                                                                ?
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Informe o sabor"
                                                                    name="name"
                                                                    disabled={this.state.complements[index]['isCreated']}
                                                                    onChange={(e) => this.handleFieldsComplementsList(index, e)}
                                                                    value={this.state.complements[index]['name']} />
                                                                :
                                                                <Autocomplete
                                                                    onSourceSelect={this.handleAutocomplete}
                                                                    nameFieldSelect={'product_id'}
                                                                    limit={10}
                                                                    inputProps={{
                                                                        id: 'product',
                                                                        label: "",
                                                                        placeholder: "Pesquisar...",
                                                                        value: this.state.product,
                                                                        url: 'search/products'
                                                                    }} />

                                                        }


                                                    </div>


                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor=""
                                                            className="control-label">Descrição</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            className="form-control"
                                                            placeholder="Descrição do produto"
                                                            name="description"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(e) => this.handleFieldsComplementsList(index, e)}
                                                            value={this.state.complements[index]['description']} />

                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor="" className="control-label">Preço
                                                            <small className="text-muted"> (Obrigatório)</small></label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="00,00"
                                                            name="price"
                                                            as={InputMask}
                                                            mask={"99,99"}
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(event) => {
                                                                // this.state.complements[index]['price'] = value
                                                                const { name, value } = event.target
                                                                let complements = [...this.state.complements];
                                                                let item = { ...complements[index] };
                                                                item[name] = value;
                                                                complements[index] = item;
                                                                this.setState({ complements });
                                                            }}
                                                            value={this.state.complements[index]['price']} />

                                                        {/* <IntlCurrencyInput
                                                            className="mb-2 form-control"
                                                            currency="BRL"
                                                            config={currencyConfig}
                                                            name="price"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                             onChange={(e, value, maskedValue) => {
                                                                // this.state.complements[index]['price'] = value
                                                                    const {name} = e.target
                                                                    let complements = [...this.state.complements];
                                                                    let item = {...complements[index]};
                                                                    item[name] = value;
                                                                    complements[index] = item;
                                                                    this.setState({complements});
                                                            }}
                                                            value={this.state.complements[index]['price']}/>*/}
                                                    </div>
                                                </Col>

                                                {
                                                    !this.state.complements[index]['isEdited'] && !this.state.complements[index]['isCreated'] &&
                                                    (
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={6}></Col>
                                                                <Col sm={6}>
                                                                    <button
                                                                        disabled={!this.state.complements[index]['name'].length >= 1}
                                                                        className="btn btn-dark btn-sm text-light text-right ml-lg-5"
                                                                        onClick={(e) => this.handleSaveNewComplement(e, index)}>
                                                                        Salvar
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                                {
                                                    this.state.complements[index]['isEdited'] &&
                                                    (
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={6}></Col>
                                                                <Col sm={6}>
                                                                    <button
                                                                        className="btn btn-danger w-md text-md-right"
                                                                        onClick={(e) => this.handleUpdateComplement(e, index)}>
                                                                        Atualizar
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                            </Col>
                                        </Row>))
                                    }
                                    <button
                                        disabled={!this.state.isEnableButtonNew}
                                        className="btn btn-dark btn-sm text-light text-right ml-lg-5"
                                        onClick={(e) => this.handleAddNewComplement(e)}>
                                        Adicionar Novo Item
                                    </button>
                                </div>
                            </PerfectScrollbar>
                        </TabPane>
                    </TabContent>
                    <Row className="text-center fixed-bottom">
                        <Col className="p-2 mb-2">
                            <button
                                className="btn btn-danger w-md"
                                onClick={() => {
                                    if (this.state.activeTab === "2") {
                                        this.toggleTab("1")
                                    } else {
                                        this.props.closeLayoutDrawer()
                                    }
                                }}>
                                {this.state.activeTab === "2" ? 'Voltar' : 'Cancelar'}
                            </button>
                        </Col>
                        <Col className="d-inline">
                            <ButtonCustom
                                isLoading={this.props.loading}
                                size="sm"
                                clazzContainer="text-center"
                                clazz="btn btn-dark w-md"
                                message_loaging="">
                                <button
                                    disabled={!this.state.name.length >= 1}
                                    className="btn btn-dark w-md"
                                    onClick={() => {

                                        if (this.state.activeTab === "2") {

                                            // this.handleValidSubmit()
                                            this.props.onCreatedSub(this.mountDataForm());
                                            console.log("Complementos ", this.mountDataForm())

                                        } else {
                                            this.toggleTab("2")
                                        }


                                    }}>
                                    {this.state.activeTab === "2" ? 'Salvar' : 'Continuar'}
                                </button>
                            </ButtonCustom>

                        </Col>


                    </Row>
                </CardBody>
            </Card>

        )
    }


}

FormProductComplement.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,





    createProduct: PropTypes.func,
    createProductSize: PropTypes.func,
    findSizesByIdCategories: PropTypes.func,
    list_complements: PropTypes.any,

    findProductComplements: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    mandatory: PropTypes.any,
    min_select: PropTypes.any,
    max_select: PropTypes.any,
    type: PropTypes.any,
    products: PropTypes.any,
    status: PropTypes.any,

    funceditEntity: PropTypes.func,
    isEditing: PropTypes.any,
    entity_id: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh,
        id,
        name,
        mandatory,
        min_select,
        max_select,
        type,
        complements
    } = state.CreateOrEditProductsComplementsGroup


    return {
        loading,
        refresh,
        id,
        name,
        mandatory,
        min_select,
        max_select,
        type,
        complements
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createProduct,
    createProductSize,
    findSizesByIdCategories,
    findProductComplements

})(
    (FormProductComplement)
)))
