import React from 'react'
import {  Col, Container, Row, TabContent, TabPane } from "reactstrap"
import {  withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import PropTypes from "prop-types";
import { Stack } from 'react-bootstrap'


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            text: '',
            answers: [],
        }
      }


    render() {

        const { answers } = this.props
        alert(answers)
        return (
            <>
                {
                    answers.length > 0 &&
                    answers.map((item, i) => {
                        return (

                            <Col className='border border-1'>
                                <Stack className='p-0 m-0' direction="horizontal" gap={2}>
                                    <div className='p-2'><a onClick={() => {
                                        const { answers } = this.props

                                        if (answers.length > 0) {
                                            answers.splice(i, 1)
                                            this.props.removeAnswers(answers)
                                        }
                                    }}><i className="bx bx-trash text-danger"></i></a></div>
                                    <div className='p-2'><p className='mt-3'>{item.text}</p></div>
                                </Stack>
                            </Col>


                        )
                    })
                }
            </>

        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    removeAnswers: PropTypes.func,
    answers: PropTypes.any,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
))) 
