import React from "react"
import { Redirect } from "react-router-dom"
import './custom.css'

// Authentication related pages
import Login from "../pages/Authentication/Login"
//import Logout from "../pages/Authentication/Logout"
//import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Logout from "../pages/Authentication/Logout";

// Companies
import Companies from "../pages/Company/index";
import CreateCompany from "../pages/Company/create/index";
import EditCompany from "../pages/Company/edit/index";

// Companies Coupons
import CouponsDiscountCompany from "../pages/Company/options/discount-coupons/index";
import PaymentMethodsCompany from "../pages/Company/options/payments-methods/index";
import AboutUsCompany from "../pages/Company/options/aboutUs/index";
import SchedulersCompany from "../pages/Company/options/opening-hours/index";
import ServiceRadiusCompany from "../pages/Company/options/service-radius/index";
import DriversCompany from "../pages/Company/options/drivers/index";
import ClassFiscalCompany from "../pages/Company/options/class-fiscal/index";

// Models Seguiments
import ModelsSeguiments from "../pages/ModelsSeguiments/index";
import CreateModelsSeguiments from "../pages/ModelsSeguiments/create/index";
import EditModelsSeguiments from "../pages/ModelsSeguiments/edit/index";

// Category
import Categories from "../pages/Categories/index";
import CreateCategory from "../pages/Categories/create/index";
import EditCategory from "../pages/Categories/edit/index";

import Edges from "../pages/Categories/Options/Edges/index";
import Pastas from "../pages/Categories/Options/Pastas/index";
import Sizes from "../pages/Categories/Options/Sizes/index";

// Payment Methods
import PaymentMethods from "../pages/PaymentsMethods/index";
import CreatePaymentMethod from "../pages/PaymentsMethods/create/index";
import EditPaymentMethod from "../pages/PaymentsMethods/edit/index";



// Discount Coupons
import DiscountCoupons from "../pages/DiscountCoupons/index";
import CreateDiscountCoupons from "../pages/DiscountCoupons/create/index";
import EditDiscountCoupons from "../pages/DiscountCoupons/edit/index";


// Products
import Products from "../pages/Categories/Options/Products/index";
// Complements
import ProductsComplements from "../pages/Categories/Options/Products/complements/FormProductComplement";


// Promotions
import Promotions from '../pages/Promotions/index'
import CreatePromotions from "../pages/Promotions/create/index";
import EditPromotions from "../pages/Promotions/edit/index";


import Tributacao from "../pages/NF/tributacao/index";
import TabIBPT from "../pages/NF/ibpt/index";
import TabCEST from "../pages/NF/cest/index";

import CreateClassificacao from "../pages/NF/classificacao/produto/create/index";
import ClassificacaoProdutos from "../pages/NF/classificacao/produto/index";
import EditClassProdutos from "../pages/NF/classificacao/produto/edit/index";

// Countries
import Countries from "../pages/Country/index";
import CreateCountries from "../pages/Country/create/index";
import EditCountries from "../pages/Country/edit/index";

// States
import States from "../pages/State/index";
import CreateState from "../pages/State/create/index";
import EditState from "../pages/State/edit/index";

// Cities
import Cities from "../pages/Cities/index";
import CreateCity from "../pages/Cities/create/index";
import EditCity from "../pages/Cities/edit/index";

// Settings
import IntroAPP from "../pages/Settings/Intro/index";
import CreateIntroAPP from "../pages/Settings/Intro/create/index";
import EditIntroAPP from "../pages/Settings/Intro/edit/index";

import EmailSettings from "../pages/Settings/Email/index";
import EmailTemplates from "../pages/Settings/Email/Layout/index";

import TipoNF from "../pages/NF/tipo/index";
import CreateTipoNF from "../pages/NF/tipo/create/index";
import EditTipoNF from "../pages/NF/tipo/edit/index";

import TabCategoryMeta from "../pages/SocialMidia/CategoryMetaFacebook/index";
import TabCategoryGoogle from "../pages/SocialMidia/CategoryGoogle/index";

import MLIntentsExpressions from "../pages/MachineLearning/expressions/index";
import MLIntents from "../pages/MachineLearning/intents/index";
import MLIntentsTranning from "../pages/MachineLearning/tranning/index";

import FormMLIntentsExpressions from "../pages/MachineLearning/expressions/form/index";
import FormMLIntentsExpressionsEdit from "../pages/MachineLearning/expressions/form_edit/index";


const authProtectedRoutes = [
    { path: "/tributacao", component: Tributacao },

    { path: "/form-class-products", component: CreateClassificacao },
    { path: "/class-products", component: ClassificacaoProdutos },
    { path: "/edit-class-prod/:entity_id", component: EditClassProdutos },

    { path: "/dashboard", component: Dashboard },

    { path: "/tipos-nf", component: TipoNF },
    { path: "/register-tipo-nf", component: CreateTipoNF },
    { path: "/edit-tipo-nf/:entity_id", component: EditTipoNF },

    { path: "/meta-facebook", component: TabCategoryMeta },
    { path: "/gmerchant", component: TabCategoryGoogle },

    { path: "/cest", component: TabCEST },
    { path: "/ibpt", component: TabIBPT },
    { path: "/promotions", component: Promotions },
    { path: "/register-promotions", component: CreatePromotions },
    { path: "/edit-promotion/:entity_id", component: EditPromotions },

    { path: "/email-settings", component: EmailSettings },
    { path: "/templates-email", component: EmailTemplates },

    { path: "/intro-app", component: IntroAPP },
    { path: "/edit-intro/:entity_id", component: EditIntroAPP },
    { path: "/register-intro", component: CreateIntroAPP },

    { path: "/companies", component: Companies },
    { path: "/register-company", component: CreateCompany },
    { path: "/edit-company/:entity_id", component: EditCompany },

    { path: "/:entity_id/coupons", component: CouponsDiscountCompany },
    { path: "/:entity_id/payment-methods", component: PaymentMethodsCompany },
    { path: "/:entity_id/about-us", component: AboutUsCompany },
    { path: "/:entity/class-fiscal", component: ClassFiscalCompany },
    { path: "/:entity_id/schedulers", component: SchedulersCompany },
    { path: "/:entity_id/service-radius", component: ServiceRadiusCompany },
    { path: "/:entity_id/drivers", component: DriversCompany },

    { path: "/models-seguiments", component: ModelsSeguiments },
    { path: "/register-model-seguiment", component: CreateModelsSeguiments },
    { path: "/edit-model-seguiment/:entity_id", component: EditModelsSeguiments },

    { path: "/categories", component: Categories },
    { path: "/register-category", component: CreateCategory },
    { path: "/edit-category/:entity_id", component: EditCategory },

    { path: "/payment-methods", component: PaymentMethods },
    { path: "/register-payment-method", component: CreatePaymentMethod },
    { path: "/edit-payment-method/:entity_id", component: EditPaymentMethod },

    { path: "/discount-coupons", component: DiscountCoupons },
    { path: "/register-discount-coupom", component: CreateDiscountCoupons },
    { path: "/edit-discount-coupom/:entity_id", component: EditDiscountCoupons },

    { path: "/:entity_id/edges", component: Edges },
    { path: "/:entity_id/pastas", component: Pastas },
    { path: "/:entity_id/sizes", component: Sizes },
    { path: "/:entity_id/:model_entity_id/products", component: Products },
    { path: "/:product_entity_id/products", component: ProductsComplements },

    { path: "/countries", component: Countries },
    { path: "/register-country", component: CreateCountries },
    { path: "/edit-country/:entity_id", component: EditCountries },

    { path: "/states", component: States },
    { path: "/register-state", component: CreateState },
    { path: "/edit-state/:entity_id", component: EditState },

    { path: "/cities", component: Cities },
    { path: "/register-city", component: CreateCity },
    { path: "/edit-city/:entity_id", component: EditCity },

    { path: "/ml-expression", component: MLIntentsExpressions },
    { path: "/ml-frm-expression", component: FormMLIntentsExpressions },
    { path: "/ml-frm-ex-edit/:entity_id", component: FormMLIntentsExpressionsEdit },
    { path: "/ml-tranning", component: MLIntentsTranning },
    { path: "/ml-intents", component: MLIntents },


    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forget-password", component: ForgetPwd },
    // { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
