import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    FIND_INTENT_EXPRESSION_ML_BY_ID,
    FIND_INTENT_ML_BY_ID,
    CREATE_INTENT_ML,
    UPDATE_INTENT_ML,
    REMOVE_INTENT_ML,
    PAGINATE_INTENT_ML,
    PAGINATE_EXPRESSION_ML,
    ADD_QUESTION_PHRASES_INTENT,
    REMOVE_QUESTION_PHRASES_INTENT,
    ADD_ANSWERS_PHRASES_INTENT,
    REMOVE_ANSWERS_PHRASES_INTENT,
} from "./actionTypes"


import {
    findExpressionMLByIdSuccess,
    allExpressionMLPaginateSuccess,
    allIntentMLPaginateSuccess,
    updateIntentMLSuccess,
    createIntentMLSuccess,
    removeIntentMLSuccess,
    addQuestionPhrasesIntentSuccess,
    removeQuestionPhrasesIntentSuccess,
    addAnswersPhrasesIntentSuccess,
    removeAnswersPhrasesIntentSuccess,
    findIntentMLByIdSuccess,
    apiIntentMlError
} from "./actions"


import {
    allPaginateExpressionMLAPI,
    allPaginateIntentMLAPI,
    updateIntentMLAPI,
    createIntentMLAPI,
    removeIntentMLAPI,
    addQuestionPhrasesIntentAPI,
    removeQuestionPhrasesIntentAPI,
    addAnswersPhrasesIntentAPI,
    removeAnswersPhrasesIntentAPI,
    findIntentMLByIdAPI,
    findExpressionMLByIdAPI

} from "../../helpers/machine_learning_helper"

function* findExpressionMLById({ payload: { id } }) {
    try {
        
        const response = yield call(findExpressionMLByIdAPI, id)

        yield put(findExpressionMLByIdSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* paginateAllExpressionML({ payload: { params } }) {
    try {

        const response = yield call(allPaginateExpressionMLAPI, params)
        console.log('row sag ', response)
        yield put(allExpressionMLPaginateSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* paginateAllIntentML({ payload: { params } }) {
    try {

        const response = yield call(allPaginateIntentMLAPI, params)
        console.log('row sag ', response)
        yield put(allIntentMLPaginateSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* addQuestionPhrasesIntent({ payload: { params } }) {
    try {

        const response = yield call(addQuestionPhrasesIntentAPI, params)
        const { data } = response;

        yield put(addQuestionPhrasesIntentSuccess(data))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* removeQuestionPhrasesIntent({ payload: { id } }) {
    try {

        const response = yield call(removeQuestionPhrasesIntentAPI, id)
        const { message } = response;

        NotificationManager.info(message, 'Aviso');


        yield put(removeQuestionPhrasesIntentSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* addAnswersPhrasesIntent({ payload: { params } }) {
    try {

        const response = yield call(addAnswersPhrasesIntentAPI, params)
        const { data } = response;

        yield put(addAnswersPhrasesIntentSuccess(data))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}
function* removeAnswersPhrasesIntent({ payload: { id } }) {
    try {

        const response = yield call(removeAnswersPhrasesIntentAPI, id)
        const { message } = response;

        NotificationManager.info(message, 'Aviso');


        yield put(removeAnswersPhrasesIntentSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}


function* findIntentMLById({ payload: { id } }) {
    try {

        const response = yield call(findIntentMLByIdAPI, id)

        yield put(findIntentMLByIdSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}
function* updateIntentML({ payload: { params } }) {
    try {

        const response = yield call(updateIntentMLAPI, params)
        const { data } = response;

        yield put(updateIntentMLSuccess(data))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* createIntentML({ payload: { params } }) {
    try {

        const response = yield call(createIntentMLAPI, params)
        const { message, error } = response;

        yield put(createIntentMLSuccess(response))
        NotificationManager.info(message, 'Aviso');
    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}

function* removeIntent({ payload: { id } }) {
    try {

        const response = yield call(removeIntentMLAPI, id)
        const { message } = response;

        NotificationManager.info(message, 'Aviso');


        yield put(removeIntentMLSuccess(response))

    } catch (error) {
        yield put(apiIntentMlError(error))
    }
}


function* MachineLearningSaga() {
    yield takeEvery(ADD_QUESTION_PHRASES_INTENT, addQuestionPhrasesIntent)
    yield takeEvery(REMOVE_QUESTION_PHRASES_INTENT, removeQuestionPhrasesIntent)
    yield takeEvery(FIND_INTENT_EXPRESSION_ML_BY_ID, findExpressionMLById)
    yield takeEvery(FIND_INTENT_ML_BY_ID, findIntentMLById)
    yield takeEvery(CREATE_INTENT_ML, createIntentML)
    yield takeEvery(UPDATE_INTENT_ML, updateIntentML)
    yield takeEvery(REMOVE_INTENT_ML, removeIntent)
    yield takeEvery(ADD_ANSWERS_PHRASES_INTENT, addAnswersPhrasesIntent)
    yield takeEvery(REMOVE_ANSWERS_PHRASES_INTENT, removeAnswersPhrasesIntent)
    yield takeEvery(PAGINATE_INTENT_ML, paginateAllIntentML)
    yield takeEvery( PAGINATE_EXPRESSION_ML, paginateAllExpressionML)
}


export default MachineLearningSaga
