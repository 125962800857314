import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home" />
                  <span className="mx-auto">{this.props.t("Dashboards")}</span>
                </Link>
              </li>

              <li>
                <Link to="/companies" className="">
                  <i className="bx bxs-business" />
                  <span>Empresas</span>
                </Link>
              </li>
              <li>
                <Link to="/payment-methods" className="">
                  <i className="bx bxs-archive-in" />
                  <span>Formas de pagamentos</span>
                </Link>
              </li>
              <li>
                <Link to="/discount-coupons" className="">
                  <i className="bx bxs-coupon" />
                  <span>Cupons de Descontos</span>
                </Link>
              </li>
              <li>
                <Link to="/promotions" className="">
                  <i className="bx bxs-coupon" />
                  <span>Promoções</span>
                </Link>
              </li>
              <li>
                <Link to="/#">
                  <i className="bx bx-category" />
                  <span>Formulário</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/models-seguiments" className="">
                      <i className="bx bx-group" />
                      <span>Modelos</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/categories" className="">
                      <i className="bx bx-grid" />
                      <span>Categorias</span>
                    </Link>
                  </li>

                </ul>
              </li>
              <li>
                <Link to="#" className="">
                  <i className="bx bx-bot" />
                  <span className="mx-auto">Machine Learning</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/ml-intents" className="">
                      <i className="bx bxs-collection" />
                      <span>Intenções</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ml-expression" className="">
                      <i className="bx bxs-quote-right" />
                      <span>Expressões</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ml-tranning" className="">
                      <i className="bx bxs-server" />
                      <span>Treinamento</span>
                    </Link>
                  </li>

                </ul>


              </li>
              <li>
                <Link to="/#">
                  <i className="bx bx-category" />
                  <span>Social Midia</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/gmerchant" className="">
                      <i className="bx bx-grid" />
                      <span>Categorias Google</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/meta-facebook" className="">
                      <i className="bx bx-grid" />
                      <span>Categorias Meta</span>
                    </Link>
                  </li>

                </ul>
              </li>
              <li>
                <Link to="/#">
                  <i className="bx bxs-paste" />
                  <span>Nota Fiscal</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/tributacao" className="">
                      <i className="bx bxs-news" />
                      <span>Tributação</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ibpt" className="">
                      <i className="bx bxs-news" />
                      <span>Tab. IBPT</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/cest" className="">
                      <i className="bx bxs-news" />
                      <span>Tab. CEST</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/class-products" className="">
                      <i className="bx bxs-server" />
                      <span>Classificação Fiscal</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/tipos-nf" className="">
                      <i className="bx bxs-server" />
                      <span>Tipos de notas</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-map-pin" />
                  <span>Região</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/countries">
                      País
                    </Link>
                  </li>
                  <li>
                    <Link to="/states">
                      Estados UF
                    </Link>
                  </li>
                  <li>
                    <Link to="/cities">
                      Cidades
                    </Link>
                  </li>

                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog" />
                  <span>Configuração</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/intro-app">
                      Intro APP
                    </Link>
                  </li>
                  <li>
                    <Link to="/email-settings">
                      {this.props.t("E-mail")}
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
