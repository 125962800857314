export const FIND_INTENT_EXPRESSION_ML_BY_ID = "FIND_INTENT_EXPRESSION_ML_BY_ID"
export const FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS = "FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS"

export const FIND_INTENT_ML_BY_ID = "FIND_INTENT_ML_BY_ID"
export const FIND_INTENT_ML_BY_ID_SUCCESS = "FIND_INTENT_ML_BY_ID_SUCCESS"

export const CREATE_INTENT_ML = "CREATE_INTENT_ML"
export const CREATE_INTENT_ML_SUCCESS = "CREATE_INTENT_ML_SUCCESS"

export const UPDATE_INTENT_ML = "UPDATE_INTENT_ML"
export const UPDATE_INTENT_ML_SUCCESS = "UPDATE_INTENT_ML_SUCCESS"

export const REMOVE_INTENT_ML = "REMOVE_INTENT_ML"
export const REMOVE_INTENT_ML_SUCCESS = "REMOVE_INTENT_ML_SUCCESS"

export const ADD_QUESTION_PHRASES_INTENT = "ADD_QUESTION_PHRASES_INTENT"
export const ADD_QUESTION_PHRASES_INTENT_SUCCESS = "ADD_QUESTION_PHRASES_INTENT_SUCCESS"

export const REMOVE_QUESTION_PHRASES_INTENT = "REMOVE_QUESTION_PHRASES_INTENT"
export const REMOVE_QUESTION_PHRASES_INTENT_SUCCESS = "REMOVE_QUESTION_PHRASES_INTENT_SUCCESS"

export const ADD_ANSWERS_PHRASES_INTENT = "ADD_ANSWERS_PHRASES_INTENT"
export const ADD_ANSWERS_PHRASES_INTENT_SUCCESS = "ADD_ANSWERS_PHRASES_INTENT_SUCCESS"

export const REMOVE_ANSWERS_PHRASES_INTENT = "REMOVE_ANSWERS_PHRASES_INTENT"
export const REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS = "REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS"

export const PAGINATE_INTENT_ML = "PAGINATE_INTENT_ML"
export const PAGINATE_INTENT_ML_SUCCESS = "PAGINATE_INTENT_ML_SUCCESS"

export const PAGINATE_EXPRESSION_ML = "PAGINATE_EXPRESSION_ML"
export const PAGINATE_EXPRESSION_ML_SUCCESS = "PAGINATE_EXPRESSION_ML_SUCCESS"


export const INTENT_ML_API_ERROR = "INTENT_ML_API_ERROR"
