import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PropTypes from "prop-types";
import { MetaTags } from 'react-meta-tags'
import Table from './table/index'
import Breadcrumbs from 'components/Breadcrumb'



class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

            columns: [
                {
                    id: 1,
                    name: 'Intensão',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Expressões',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Ações',
                    status: 1
                },
            ],


        }
    }

    componentDidMount() {

    }



    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Expressões Machine Learning</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Expressões Machine Learning" breadcrumbItem="Listagem" />
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>

                                        <Table
                                            refresh={this.props.refresh}
                                            route_edit="/ml-frm-ex-edit"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>



            </React.Fragment>
        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,


}
const mapStateToProps = state => {


    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
