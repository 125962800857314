import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";

const allPaginateIntentMLAPI = ({ page, limit }) => get(url.GET_INTENT_ML_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const allPaginateExpressionMLAPI = ({ page, limit }) => get(url.GET_EXPRESSION_ML_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const addAnswersPhrasesIntentAPI = data => post(url.POST_ADD_ANSWERS_PHRASES_INTENT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const addQuestionPhrasesIntentAPI = data => post(url.POST_ADD_QUESTION_PHRASES_INTENT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

    const findExpressionMLByIdAPI = data => get(url.GET_EXPRESSION_ML_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


const findIntentMLByIdAPI = data => get(url.GET_INTENT_ML_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const createIntentMLAPI = data => post(url.POST_CREATE_INTENT_ML, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const updateIntentMLAPI = data => put(url.PUT_EDIT_INTENT_ML, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const removeQuestionPhrasesIntentAPI = data => del(url.DELETE_QUESTION_PHRASES_INTENT.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const removeIntentMLAPI = data => del(url.DELETE_INTENT_ML.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const removeAnswersPhrasesIntentAPI = data => del(url.DELETE_ANSWERS_PHRASES_INTENT.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
export {
    updateIntentMLAPI,
    createIntentMLAPI,
    removeIntentMLAPI,
    addQuestionPhrasesIntentAPI,
    removeQuestionPhrasesIntentAPI,
    addAnswersPhrasesIntentAPI,
    removeAnswersPhrasesIntentAPI,
    findIntentMLByIdAPI,
    allPaginateIntentMLAPI,
    allPaginateExpressionMLAPI,
    findExpressionMLByIdAPI
}
