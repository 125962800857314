import React from 'react'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation"
import Autocomplete from 'components/Autocomplete'
import Breadcrumbs from 'components/Breadcrumb'
import { Stack } from 'react-bootstrap'
import ButtonCustom from 'components/ButtonCustom'
import { apiIntentMlError, updateIntentML, addQuestionPhrasesIntent } from "../../../../store/actions";


class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            text: '',
            answers: [],
        }
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        this.props.addQuestionPhrasesIntent({
            ml_intent_id: this.state.ml_intent_id,
            text: values.text,
            answers: this.state.answers.length > 0 ? this.state.answers : null
        })

    }


    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState['ml_intent_id'] = code

          //  console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)

            this.setState(newState)

        }
    }

    handleOnChange(e) {

        const { id, name, value } = e.target
        let newobj = {}
        newobj[name] = value
        this.setState(newobj)
    }


    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Expressões ML</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Expressões"
                            breadcrumbItem="Cadastrar" />
                        <Row>

                            <Col sm={6}>

                                <Card>
                                    <CardBody>
                                        <Row>


                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                <Col sm={12} className="mt-2">
                                                    <Autocomplete
                                                        onSourceSelect={this.handleAutocomplete}
                                                        nameFieldSelect={'ml_intent_id'}
                                                        limit={10}
                                                        inputProps={{
                                                            id: 'intent',
                                                            label: "Intenções de conversa",
                                                            placeholder: "Pesquisar a intenção...",
                                                            value: this.state.intent ? this.state.intent : this.props.intent,
                                                            url: 'search/ml/intents',
                                                            param_id: ''
                                                        }} />
                                                </Col>
                                                <Col sm={12}>
                                                    <p className="card-title-desc mt-4">
                                                        Instrução sobre a configuração do ritimo das intenções da conversação.
                                                    </p>
                                                    <li>Expressão da conversa : Abordagem inicial (Ex: Bom dia, olá, oi)</li>
                                                    <li>Resposta para expressão: Devolutiva da NPL AI (ex: Bom dia, oi como vai?)</li>
                                                </Col>
                                                <Col sm={12} className="mt-3">
                                                    <Stack direction="horizontal" gap={this.state.disable_field ? 2 : 1}>
                                                        <div className='w-75'>
                                                            <AvField
                                                                name="text"
                                                                label="Expressão da conversa"
                                                                className="form-control"
                                                                placeholder="Ex: oi, olá"
                                                                disabled={this.state.disable_field}
                                                                value={this.state.text ? this.state.text : this.props.text}
                                                                onChange={(e) => this.handleOnChange(e)}
                                                                onBlur={(e) => {
                                                                    this.setState({ disable_field: true })

                                                                }}
                                                                type="text"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                this.state.disable_field &&
                                                                <a onClick={() => { this.setState({ disable_field: false }) }}><i className="bx bx-pencil text-dark"></i></a>

                                                            }
                                                        </div>
                                                    </Stack>

                                                </Col>

                                                <Col sm={12} className="mt-2">
                                                    <AvField
                                                        name="answers"
                                                        label=""
                                                        className="form-control"
                                                        placeholder="Resposta para expressão"
                                                        value={this.state.answers_text}
                                                        onBlur={(e) => {
                                                            const { id, name, value } = e.target
                                                            const { answers, answers_text } = this.state

                                                            if (typeof value === "string" && value.length > 0) {
                                                                answers.push({
                                                                    text: value
                                                                })
                                                                this.setState({ answers: answers, answers_text: '' })
                                                            }
                                                        }}
                                                        type="text"
                                                    />

                                                </Col>

                                                <Col className="inner-custom-pagination d-flex" sm={12}>
                                                    <ButtonCustom
                                                        isLoading={this.props.loading}
                                                        size="sm"
                                                        clazzContainer="text-center"
                                                        clazz="btn btn-dark w-md"
                                                        message_loaging=" aguarde ...">
                                                        <button
                                                            className="btn btn-dark w-md"
                                                            type="submit">
                                                            Salvar
                                                        </button>
                                                    </ButtonCustom>

                                                </Col>
                                            </AvForm>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm={6}>

                                <Card>
                                    <CardBody>
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span
                                                    className="h4 card-title">{this.state.text}</span>
                                                <p className="card-title-desc mt-2">
                                                    Lista de Expressão de resposta, de acordo com a expressão da conversa.
                                                </p>

                                            </div>
                                        </Col>

                                        {
                                            this.state.answers.length > 0 &&
                                            this.state.answers.map((item, i) => {
                                                return (

                                                    <Col className='border border-1'>
                                                        <Stack className='p-0 m-0' direction="horizontal" gap={2}>
                                                            <div className='p-2'><a onClick={() => {
                                                                const { answers } = this.state

                                                                if (answers.length > 0) {
                                                                    answers.splice(i, 1)
                                                                    this.setState({ answers: answers, answers_text: '' })
                                                                }
                                                            }}><i className="bx bx-trash text-danger"></i></a></div>
                                                            <div className='p-2'><p className='mt-3'>{item.text}</p></div>
                                                        </Stack>
                                                    </Col>


                                                )
                                            })
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>

        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    text: PropTypes.any,
    company: PropTypes.any,
    intent: PropTypes.any,
    addQuestionPhrasesIntent: PropTypes.func



}
const mapStateToProps = state => {

    const {
        loading,
        refresh,

    } = state.MLIntents
    return {
        loading,
        refresh,

    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiIntentMlError,
    updateIntentML,
    addQuestionPhrasesIntent
})(
    (Index)
)))
