import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createProduct, uploadProducts, findProductGeneric, editProduct } from "../../../../store/categories/options/products/actions"
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames"
import InputMask from 'react-input-mask';
import { NotificationManager } from "react-notifications";
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Form, Stack } from "react-bootstrap";

import Dropzone from "react-dropzone";
import ButtonCustom from "../../../../components/ButtonCustom";
import ReactDrawer from "react-drawer";
import FormEditProductComplement from "./complements/FormEditProductComplement";


class FormEditProductGeneric extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            components: [],
            name: '',
            description: '',
            sku: '',
            price: 0,
            image: '',
            is_product: null,
            product_id: 0,
            activeTab: "1",
            has_addons: null,
            file: null,
            existsFile: false,

            open: false,
            position: 'right',
            noOverlay: false,

            isValidName: false,
            isValidPrice: false,

            path_file_before_image: '',
            file_image: null,
            existsFile_image: false

        }
        this.handleFields = this.handleFields.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleValidFields = this.handleValidFields.bind(this)

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setComponents = this.setComponents.bind(this);
        this.setNoOverlay = this.setNoOverlay.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { entity_id, isEditing } = this.props
        if (isEditing) {
            if (parseInt(entity_id) > 0) {
                this.props.findProductGeneric(entity_id)
                this.props.funceditEntity()
            }

        }


    }

    handleValidFields() {
        const { isEditing } = this.props
        console.log("TAB ", this.state.activeTab)
        /* if(isEditing === false){
             if (parseInt(this.state.activeTab) === 1) {
             if (this.state.name === '' || this.state.name === null) {
 
                 this.setState({isValidName: !this.state.isValidName});
                 return false;
             } else {
                 this.setState({isValidName: !this.state.isValidName});
                 return true;
             }
         }
             if (parseInt(this.state.activeTab) === 2) {
             if (this.state.price === '' || this.state.price === null) {
                 this.setState({isValidPrice: !this.state.isValidPrice});
                 return false;
             } else {
                 this.setState({isValidPrice: !this.state.isValidPrice});
                 return true;
             }
         }
         }*/
        return true;
    }

    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open });
    }

    closeDrawer() {
        this.setState({ open: false });
    }

    onDrawerClose() {
        this.setState({ open: false });
    }

    handleValidSubmit() {
        const { entity_id } = this.props.match.params

        if (this.state.has_addons === 1 && this.state.components.length === 0) {
            NotificationManager.info('Informe os complementos do produto obrigatório, para continuar.', 'Aviso!');
            return;
        }

        this.props.editProduct({
            id: this.props.id,
            sku: this.state.sku,
            is_product: this.state.is_product ? this.state.is_product : this.props.is_product,
            description: this.state.description,
            menu_category_id: entity_id,
            has_addons: this.state.has_addons ? this.state.has_addons : this.props.has_addons,
            name: this.state.name,
            price: this.state.price,
            components: this.state.components,
        }, this.props.history)



    }

    setComponents(data) {
        this.setState({ components: data })
        this.onDrawerClose()
    }

    handleFields(event) {

        const { name, value } = event.target
        let tempState = {}
        tempState[name] = value
        this.setState(tempState)

    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const { entity_id } = this.props
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_image !== '' ? this.state.path_file_before_image : this.props.image);
        this.props.uploadProducts(data)

    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    render() {
        const { entity_id, isEditing } = this.props
        const { url_image, loading_form, complements } = this.props;
        return (
            <div>
                <Card>
                    <CardBody>
                        <Row className="align-items-md-center  mb-3">
                            <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                    <h4 className="card-title mb-4">{this.state.name ? this.state.name : this.props.name}</h4>
                                </div>
                                <div className="text-md-right ms-auto p-2">
                                    <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                                </div>
                            </Col>
                        </Row>

                        <ul className="nav nav-tabs nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: parseInt(this.state.activeTab) === 1,
                                    })}
                                >
                                    Detalhe
                                </NavLink>
                            </NavItem>
                            {/*<NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "2",
                                    })}
                                >
                                    {this.props.t("Price")}
                                </NavLink>
                            </NavItem>*/}
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: parseInt(this.state.activeTab) === 2,
                                    })}
                                >
                                    Complementos
                                </NavLink>
                            </NavItem>
                        </ul>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className="py-4"
                        >
                            <TabPane tabId="1">
                                <PerfectScrollbar style={{ height: "410px" }}>
                                    <Row>
                                        <Col sm={7}>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor=""
                                                            className="control-label">Sabor ou Nome</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Informe o sabor"
                                                            name="name"
                                                            isInvalid={this.state.isValidName}
                                                            onChange={this.handleFields}
                                                            value={this.props.name ? this.props.name : this.state.name} />

                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">Descrição</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={7}
                                                            className="form-control"
                                                            placeholder="Descrição do produto"
                                                            name="description"
                                                            onChange={this.handleFields}
                                                            value={this.state.description ? this.state.description : this.props.description} />

                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="form-group">
                                                        <label htmlFor=""
                                                            className="control-label">Preço <small
                                                                className="text-muted"> Obrigatório</small></label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="00,00"
                                                            name="price"
                                                            as={InputMask}
                                                            mask={"99,99"}
                                                            isInvalid={this.state.isValidPrice}
                                                            onChange={(event) => {

                                                                const { name, value } = event.target
                                                                console.log('prod ', { name, value })
                                                                this.setState({ price: value })

                                                            }}
                                                            value={this.state.price ? this.state.price : this.props.price} />
                                                    </div>

                                                </Col>
                                                <Col>
                                                    <div className="mt-2" onChange={(event) => {
                                                        this.handleFields(event)
                                                    }}>
                                                        <label htmlFor=""
                                                            className="control-label">É um produto?</label>

                                                        <Stack direction="horizontal" gap={2}>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="is_product"
                                                                    id="is_product"
                                                                    value="1"
                                                                    checked={((this.state.is_product != null && parseInt(this.state.is_product) === 1 || parseInt(this.props.is_product) === 1))}

                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="is_product"
                                                                >
                                                                    Sim
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="is_product"
                                                                    id="is_product"
                                                                    value="0"
                                                                    checked={((this.state.is_product != null && parseInt(this.state.is_product) === 0 || parseInt(this.props.is_product) === 0))}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="is_product"
                                                                >
                                                                    Não
                                                                </label>
                                                            </div>
                                                        </Stack>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={5}>
                                            <Row>
                                                <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">
                                                        <label
                                                            className="control-label">Foto</label>

                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <Dropzone onDrop={this.handleDrop}>

                                                        {({ getRootProps, getInputProps }) => (

                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {url_image !== ''
                                                                    ?
                                                                    <img className="thumbnail img-responsive img-icon-prod"
                                                                        src={url_image ? this.state.image !== '' ? this.state.image : url_image : this.state.image} />
                                                                    : <p style={{ marginTop: '40%' }}
                                                                        className="text-center">Arraste e
                                                                        solte ou
                                                                        clique
                                                                        para adicionar a imagem</p>
                                                                }

                                                            </div>

                                                        )}

                                                    </Dropzone>
                                                </Col>

                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">{this.props.t("SKU")}</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="123"
                                                            name="sku"
                                                            onChange={this.handleFields}
                                                            value={this.state.sku ? this.state.sku : this.props.sku} />

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </PerfectScrollbar>
                            </TabPane>
                            {/*<TabPane tabId="2">
                                <Row>

                                    <Col sm={12}>
                                        <div className="form-group">
                                            <label htmlFor=""
                                                className="control-label">{this.props.t("Price")} <small
                                                    className="text-muted"> ({this.props.t("Mandatory")})</small></label>
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="00,00"
                                                        name="price"
                                                        as={InputMask}
                                                        mask={"99,99"}
                                                        isInvalid={this.state.isValidPrice}
                                                        onChange={(e, value, maskedValue) => {

                                                            const { name } = e.target
                                                            this.setState({ price: value })

                                                        }}
                                                        value={this.state.price ? parseFloat(this.state.price).toFixed(2) : parseFloat(this.props.price).toFixed(2)} />

                                                    <IntlCurrencyInput 
                                                        className="mb-2 form-control"
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        name="price"
                                                        isInvalid={this.state.isValidPrice}
                                                        onChange={(e, value, maskedValue) => {

                                                            const { name } = e.target
                                                            this.setState({ price: value })

                                                        }}
                                                        value={this.state.price ? parseFloat(this.state.price).toFixed(2) : parseFloat(this.props.price).toFixed(2)} />
                        </Col>
                        <Col className="p-1">
                            <button
                                className="btn-outline-dark w-md rounded"
                                onClick={() => {


                                }}>
                                {this.props.t("Apply Discount")}
                            </button>
                        </Col>
                    </Row>
            </div>
                                    </Col >

                                </Row >
                            </TabPane >*/}
                            < TabPane tabId="2" >
                                <PerfectScrollbar style={{ height: "410px" }}>
                                    <Row>

                                        <Col sm={12}>
                                            <div className="d-inline">
                                                <span className="h3 card-title">Complementos</span>
                                                <p className="card-title-desc mt-2">Seu item tem complementos pra ficar
                                                    ainda mais gostoso? Indique aqui.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="card-header rounded">
                                                <span className="h3 card-title">Escolha uma opção</span>
                                                <div className="form-group mt-4">
                                                    <Form.Check
                                                        type="radio"
                                                        checked={this.props.has_addons ? (parseInt(this.props.has_addons) === 0) : (parseInt(this.state.has_addons) === 0)}
                                                        label="Não, este item não tem complementos"
                                                        name="has_addons"
                                                        className="card-title-desc"
                                                        onChange={(event) => {

                                                            const { name, value } = event.target

                                                            let tempState = {}
                                                            tempState[name] = parseInt(value)
                                                            this.props.has_addons = parseInt(value)
                                                            this.setState(tempState)
                                                        }}
                                                        value="0" />

                                                </div>
                                                <div className="form-group">
                                                    <Form.Check
                                                        type="radio"
                                                        checked={this.props.has_addons ? (parseInt(this.props.has_addons) === 1) : (parseInt(this.state.has_addons) === 1)}
                                                        label="Sim, este item tem complementos"
                                                        className="card-title-desc"
                                                        name="has_addons"
                                                        onChange={(event) => {

                                                            const { name, value } = event.target
                                                            let tempState = {}
                                                            tempState[name] = parseInt(value)
                                                            this.props.has_addons = parseInt(value)
                                                            this.setState(tempState)
                                                        }}
                                                        value="1" />

                                                </div>
                                            </div>

                                        </Col>
                                        {
                                            this.state.has_addons != null && parseInt(this.state.has_addons) == 1 || this.props.has_addons != null && parseInt(this.props.has_addons) == 1 &&
                                            <Col sm={12} className="mt-2">
                                                <button
                                                    onClick={this.toggleDrawer}
                                                    disabled={this.state.open && !this.state.noOverlay}
                                                    className="btn btn-dark  rounded text-center">
                                                    <i className="bx bx-plus"></i>
                                                    {( (parseInt(this.props.has_addons) === 1) || (parseInt(this.state.has_addons) === 1) ? ' Editar lista de complementos' : ' Grupo de complementos')} 
                                                </button>
                                            </Col>
                                        }


                                    </Row>
                                </PerfectScrollbar>
                            </TabPane >
                        </TabContent >
                        <Row className="text-center fixed-bottom">
                            <Col className="p-2 mb-2">
                                <button
                                    className="btn btn-danger w-md"
                                    onClick={() => {
                                        if (parseInt(this.state.activeTab) === 2) {
                                            this.toggleTab((parseInt(this.state.activeTab) - 1).toString())
                                        } else {
                                            this.props.closeLayoutDrawer()
                                        }
                                    }}>
                                    {parseInt(this.state.activeTab) === 2 ? 'Voltar' : 'Cancelar'}
                                </button>
                            </Col>
                            <Col className="d-inline">

                                <ButtonCustom
                                    isLoading={this.props.loading}
                                    size="sm"
                                    clazzContainer="text-center"
                                    clazz="btn btn-dark w-md"
                                    message_loaging="">
                                    <button
                                        disabled={!this.props.name.length >= 1}
                                        className="btn btn-dark w-md"
                                        onClick={() => {
                                            if (parseInt(this.state.activeTab) === 2) {
                                                this.handleValidSubmit()
                                            } else {
                                                if (this.handleValidFields()) {
                                                    this.toggleTab((parseInt(this.state.activeTab) + 1).toString())
                                                }

                                            }

                                        }}>
                                        {parseInt(this.state.activeTab) === 2 ? 'Salvar' : 'Continuar'}
                                    </button>
                                </ButtonCustom>

                            </Col>


                        </Row>
                    </CardBody >
                </Card >
                {
                    this.props.entity_id > 0 &&
                    <ReactDrawer
                        open={this.state.open}
                        position={this.state.position}
                        onClose={this.onDrawerClose}
                        noOverlay={this.state.noOverlay}>

                        <FormEditProductComplement
                            entity_id={this.props.entity_id}
                            isEditing={this.props.isEditing}
                            list_complements={complements ?? []}
                            onCreatedSub={this.setComponents}
                            closeLayoutDrawer={this.closeDrawer}
                        />
                    </ReactDrawer>
                }
            </div >

        )
    }


}

FormEditProductGeneric.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,

    rows: PropTypes.array,
    product_id: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    sku: PropTypes.any,
    qtd_flavors: PropTypes.any,
    qtd_pieces: PropTypes.any,
    status: PropTypes.any,
    id: PropTypes.any,
    menu_category_id: PropTypes.any,
    ean: PropTypes.any,
    price: PropTypes.any,
    perc_discount: PropTypes.any,
    serve: PropTypes.any,
    weight: PropTypes.any,
    has_addons: PropTypes.any,
    createProduct: PropTypes.func,
    editProduct: PropTypes.func,
    funceditEntity: PropTypes.func,
    findProductGeneric: PropTypes.func,
    isEditing: PropTypes.any,
    entity_id: PropTypes.any,
    is_product: PropTypes.any,
    image: PropTypes.any,
    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,

}
const mapStateToProps = state => {


    /*const {
        name,
        sku,
        qtd_flavors,
        qtd_pieces,
        status,
        menu_category_id,
        rows
    } = state.CreateOrEditSizes*/

    const {
        loading,
        refresh,
        id,
        name,
        sku,
        description,
        image,
        status,
        menu_category_id,
        sizes,
        product_id,
        type,
        ean,
        price,
        perc_discount,
        serve,
        weight,
        has_addons,
        complements,
        url_image,
        type_dir_file,
        is_product
    } = state.CreateOrEditProducts
    return {
        loading,
        refresh,
        is_product,
        id,
        name,
        sku,
        description,
        image,
        status,
        menu_category_id,
        sizes,
        product_id,
        type,
        ean,
        price,
        perc_discount,
        serve,
        weight,
        has_addons,
        url_image,
        complements,
        type_dir_file
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createProduct,
    findProductGeneric,
    editProduct,
    uploadProducts

})(
    (FormEditProductGeneric)
)))
