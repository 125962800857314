import {
    FIND_INTENT_EXPRESSION_ML_BY_ID,
    FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS,
    FIND_INTENT_ML_BY_ID,
    FIND_INTENT_ML_BY_ID_SUCCESS,
    CREATE_INTENT_ML,
    CREATE_INTENT_ML_SUCCESS,
    UPDATE_INTENT_ML,
    UPDATE_INTENT_ML_SUCCESS,
    REMOVE_INTENT_ML,
    REMOVE_INTENT_ML_SUCCESS,
    ADD_QUESTION_PHRASES_INTENT,
    ADD_QUESTION_PHRASES_INTENT_SUCCESS,
    REMOVE_QUESTION_PHRASES_INTENT,
    REMOVE_QUESTION_PHRASES_INTENT_SUCCESS,
    ADD_ANSWERS_PHRASES_INTENT,
    ADD_ANSWERS_PHRASES_INTENT_SUCCESS,
    REMOVE_ANSWERS_PHRASES_INTENT,
    REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS,
    PAGINATE_INTENT_ML,
    PAGINATE_INTENT_ML_SUCCESS,
    PAGINATE_EXPRESSION_ML,
    PAGINATE_EXPRESSION_ML_SUCCESS,
    INTENT_ML_API_ERROR
} from "./actionTypes"


export const findExpressionMLById = id => {
    
    return {
        type: FIND_INTENT_EXPRESSION_ML_BY_ID,
        payload:  {id},
    }
}

export const findExpressionMLByIdSuccess = params => {
    return {
        type: FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS,
        payload: params
    }
} 

export const allExpressionMLPaginate = params => {
    return {
        type: PAGINATE_EXPRESSION_ML,
        payload: { params },
    }
}

export const allExpressionMLPaginateSuccess = params => {
    
    return {
        type: PAGINATE_EXPRESSION_ML_SUCCESS,
        payload: params,
    }
}

export const allIntentMLPaginate = params => {
    return {
        type: PAGINATE_INTENT_ML,
        payload: { params },
    }
}

export const allIntentMLPaginateSuccess = params => {
    
    return {
        type: PAGINATE_INTENT_ML_SUCCESS,
        payload: params,
    }
}

export const updateIntentML = params => {
    return {
        type: UPDATE_INTENT_ML,
        payload: { params },
    }
}

export const updateIntentMLSuccess = params => {
    return {
        type: UPDATE_INTENT_ML_SUCCESS,
        payload: params,
    }
}

export const createIntentML = params => {
    return {
        type: CREATE_INTENT_ML,
        payload: { params },
    }
}

export const createIntentMLSuccess = params => {
    return {
        type: CREATE_INTENT_ML_SUCCESS,
        payload: params,
    }
}

export const removeIntentML = params => {
    return {
        type: REMOVE_INTENT_ML,
        payload: { params },
    }
}

export const removeIntentMLSuccess = params => {
    return {
        type: REMOVE_INTENT_ML_SUCCESS,
        payload: { params }
    }
}

export const addQuestionPhrasesIntent = params => {
    return {
        type: ADD_QUESTION_PHRASES_INTENT,
        payload: { params },
    }
}

export const addQuestionPhrasesIntentSuccess = params => {
    return {
        type: ADD_QUESTION_PHRASES_INTENT_SUCCESS,
        payload: { params }
    }
}

export const removeQuestionPhrasesIntent = params => {
    return {
        type: REMOVE_QUESTION_PHRASES_INTENT,
        payload: { params },
    }
}

export const removeQuestionPhrasesIntentSuccess = params => {
    return {
        type: REMOVE_QUESTION_PHRASES_INTENT_SUCCESS,
        payload: { params }
    }
}
 
export const addAnswersPhrasesIntent = params => {
    return {
        type: ADD_ANSWERS_PHRASES_INTENT,
        payload: { params },
    }
}

export const addAnswersPhrasesIntentSuccess = params => {
    return {
        type: ADD_ANSWERS_PHRASES_INTENT_SUCCESS,
        payload: { params }
    }
}

export const removeAnswersPhrasesIntent = params => {
    return {
        type: REMOVE_ANSWERS_PHRASES_INTENT,
        payload: { params },
    }
}

export const removeAnswersPhrasesIntentSuccess = params => {
    return {
        type: REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS,
        payload: { params }
    }
}

export const findIntentMLById = id => {
    return {
        type: FIND_INTENT_ML_BY_ID,
        payload: {id},
    }
}

export const findIntentMLByIdSuccess = params => {
    return {
        type: FIND_INTENT_ML_BY_ID_SUCCESS,
        payload: params
    }
}



export const apiIntentMlError = error => {
    return {
        type: INTENT_ML_API_ERROR,
        payload: { error },
    }
}

