import {
    FIND_INTENT_EXPRESSION_ML_BY_ID,
    FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS,
    FIND_INTENT_ML_BY_ID,
    FIND_INTENT_ML_BY_ID_SUCCESS,
    CREATE_INTENT_ML,
    CREATE_INTENT_ML_SUCCESS,
    UPDATE_INTENT_ML,
    UPDATE_INTENT_ML_SUCCESS,
    REMOVE_INTENT_ML,
    REMOVE_INTENT_ML_SUCCESS,
    ADD_QUESTION_PHRASES_INTENT,
    ADD_QUESTION_PHRASES_INTENT_SUCCESS,
    REMOVE_QUESTION_PHRASES_INTENT,
    REMOVE_QUESTION_PHRASES_INTENT_SUCCESS,
    ADD_ANSWERS_PHRASES_INTENT,
    ADD_ANSWERS_PHRASES_INTENT_SUCCESS,
    REMOVE_ANSWERS_PHRASES_INTENT,
    REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS,
    PAGINATE_INTENT_ML,
    PAGINATE_INTENT_ML_SUCCESS,
    PAGINATE_EXPRESSION_ML,
    PAGINATE_EXPRESSION_ML_SUCCESS,
    INTENT_ML_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    rows: [],
    id: 0,

    text: '',
    intent: null,
    answers: []
}

const machineLearning = (state = initialState, action) => {

    switch (action.type) {
        case PAGINATE_EXPRESSION_ML:
        case PAGINATE_INTENT_ML:
        case FIND_INTENT_ML_BY_ID:
        case FIND_INTENT_EXPRESSION_ML_BY_ID:
        case CREATE_INTENT_ML:
        case UPDATE_INTENT_ML:
        case REMOVE_INTENT_ML:
        case ADD_QUESTION_PHRASES_INTENT:
        case REMOVE_QUESTION_PHRASES_INTENT:
        case ADD_ANSWERS_PHRASES_INTENT:
        case REMOVE_ANSWERS_PHRASES_INTENT:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_INTENT_EXPRESSION_ML_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false,
                id: action.payload.result.id,
                text: action.payload.result.text,
                intent: action.payload.result.intent.text,
                answers: action.payload.result.answers
            }
            break
        case FIND_INTENT_ML_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload,
                loading: false
            }
            break
        case PAGINATE_EXPRESSION_ML_SUCCESS:
        case PAGINATE_INTENT_ML_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.current_page,
                last_page: action.payload.last_page,
                total: action.payload.total,
                from: action.payload.from,
                to: action.payload.to,
                rows: action.payload.data,
                loading: false
            }
            break
        case CREATE_INTENT_ML_SUCCESS:
        case UPDATE_INTENT_ML_SUCCESS:
        case REMOVE_INTENT_ML_SUCCESS:
        case ADD_QUESTION_PHRASES_INTENT_SUCCESS:
        case REMOVE_QUESTION_PHRASES_INTENT_SUCCESS:
        case ADD_ANSWERS_PHRASES_INTENT_SUCCESS:
        case REMOVE_ANSWERS_PHRASES_INTENT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case INTENT_ML_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break

        default:
            state = { ...state }
            break
    }

    return state
}
export default machineLearning
